.what-we-do-img {
    width: 60%;
    height: 450px;

    transition: all .3s;
    position: relative;
    pointer-events: none;
}

.what-we-do-img__src {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    transition: all .3s;
}

.active-image {
    opacity: 1;
    position: static;
}

@media (max-width: 950px) {
    .what-we-do-img {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .what-we-do-img {
        height: 300px;
    }
}

@media (max-width: 600px) {
    .what-we-do-img {
        height: 200px;
    }
}