.footer {
    background: #003558;
}

.footer-box {
    display: flex;
    flex-direction: column;

    max-width: 1536px;
    margin: 0 auto;

    min-height: 5rem;

    padding: 1rem;
}

.location-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .1rem;

    flex-direction: column;
}

.footer-box__rights,
.footer-box__location {
    color: var(--clr);
    text-align: center;
}

.footer-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 3rem 0;
}

.terms-and-conditions {
    text-align: center;

    color: var(--clr);
    font-size: 1.1rem;

    transition: color .3s;
}

.terms-and-conditions:hover {
    color: var(--bClr);
}