.buttons {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.processes__button {
    color: var(--clr);
    transition: color .3s;
    font-size: 1.1rem;
}

.processes__button:hover {
    color: var(--bClr);
}

.processes__button:disabled {
    color: var(--bClr);
    opacity: 1;
}

.processes-list {
    display: flex;
    flex-direction: column;

    gap: 2rem;

    margin-top: 2rem;
}

.processes-list__element {
    color: var(--clr);

    font-size: 1.2rem;
}

.loading-line {
    display: flex;
    width: 100%;
    height: 3px;

    position: relative;
    margin: 2rem 0;
    background: var(--clr);
    transition: all .3s;
}

.loading-line__anchor {
    content: '';
    width: 15px;
    height: 10px;
    border-radius: 50%;
    background: var(--bClr);

    position: absolute;
    top: 50%;
    left: 10%;

    transform: translateY(-50%);

    transition: all .3s;
}

@media (max-width: 375px) {
    .processes__button {
        font-size: .8rem;
    }
}