.contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
}

.preloading-animation {
    position: absolute;

    top: 40%;
    left: 50%;

    transform: translate(-50%, -50%);
    object-fit: cover;

    width: 250px;
    height: 250px;

    z-index: 20;

    opacity: .5;
}

.preloading-animation img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    animation: rotation 10s linear infinite;
}

.contact-us-form-submit-btn {
    width: 250px;
    padding: 1rem;

    transition: all .3s;
}

.contact-us-form-submit-btn:disabled {
    opacity: .4;
    pointer-events: none;
    border: 1px solid var(--clr);
}

.form-sent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;

    width: 100%;
    height: 100%;

    background: var(--mpClr);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    padding: 0 1rem;
}

.form-sent__title {
    font-size: 2.5rem;
    text-align: center;
    color: var(--bClr);
}

.form-sent__text {
    font-size: 1.5rem;
    color: var(--clr);
    text-align: center;
}

.form-sent_404 {
    z-index: 45;
}

@media (max-width: 950px) {
    .contact-us-form {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .contact-us-form-submit-btn {
        width: 100%;
    }
}