.header-box-bg .header-box {
    background: transparent;
    transition: all .5s;
}

.header-box {
    width: 100%;
    
    background: var(--mpClr);

    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 1rem;

    z-index: 100;

    transition: all .5s;
}

.header-box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 1536px;
    margin: 0 auto;

    padding: 1.5rem 0;
}