:root {
  --clr: white;       /*common color*/
  --clrHov: #ddad35;  /*hover color*/

  --bClr: #ddad35;    /*border color*/

  --mpClr: #003558;
  --mpSClr: #424241;

  --invClr: #e85f5f; /*contact us form invalid color*/
  --fldClr: #42d4db; /*contact us form filled\ color*/
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  /* background: #003558; */
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  background: none;
  border: none;

  cursor: pointer;
}

label {
  display: block;
}

button, input {
  margin: 5px 0;
}

input {
  padding: 10px;
  width: 100%;
}

@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  html {
    min-width: 320px;
  }
}