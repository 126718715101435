.logo {
    width: 120px;
    height: 4rem;

    border-radius: 50%;

    padding: 1.5rem;

    border-right: 8px solid white;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 2rem;

    transition: all .3s ease-in-out;

    position: relative;
}

.logo span {
    color: var(--bClr);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-wrap: nowrap;
    word-spacing: .5rem;
    font-size: 1rem;
    width: 180px;

    position: absolute;
    top: 100%;
    left: 90%;

    transform: translateX(-50%);
}


.logo:hover {
    border-right: 8px solid var(--bClr);
    color: var(--clrHov);
}