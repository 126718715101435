
.experties-photo {
    width: 450px;
    height: 450px;
}

.experties-photo__src {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
}

.experties-box {
    display: flex;
    flex-direction: column;
    width: 40%;

    gap: 2rem;
}

.experties-box__title {
    color: var(--mpClr);
    font-size: 2.3rem;
}

.experties-box__subtitle {
    color: var(--mpClr);
    font-size: 1.5rem;

    font-weight: normal;
}

.experties-box__text {
    font-size: 1.3rem;
    color: var(--mpSClr);
}

@media (max-width: 950px) {
    .experties-box {
        width: 100%;
    }

    .experties-photo {
        width: 350px;
        height: 350px;
    }

    .additional-info_experties {
        flex-direction: column-reverse;
        gap: 1rem;
    }
}

@media (max-width: 600px) {
    .experties-photo {
        width: 300px;
        height: 300px;
    }
}