.header {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    padding-top: 1rem;
}

.header__bg {
    width: 100vw;
    height: 100vh;

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    object-fit: cover;
}

@media (max-width: 950px) {
    .header {
        height: 40vh;
    }

    .header__bg {
        height: 100%;
    }
}