.ship-page-content-box {
    display: flex;
    gap: 2rem;
    width: 100%;
    min-height: 600px;

    padding: 3rem 0;
}

.ship-page-content-box_reversed {
    flex-direction: row-reverse;
}

.ship-page-content-box-images {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 3rem;
}

.ship-page-content-box-info {
    width: 40%;
    display: flex;
    gap: 4rem;
    flex-direction: column;
}

.ship-page-content-box-info__title {
    color: var(--mpClr);
    font-size: 2.5rem;
    text-align: center;
}

.ship-page-content-box-info__text {
    color: var(--mpSClr);
    font-size: 1.5rem;
}

.ship-page-content-box-image {
    width: 45%;
    height: 410px;
}

.ship-page-content-box-image:last-child {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
}

.ship-page-content-box_reversed .ship-page-content-box-image:last-child {
    position: static;
}


.ship-page-content-box-image__src {
    width: 100%;
    height: 100%;
    object-fit: contain;

    border-radius: 50%;
}


@media (max-width: 950px) {

    .ship-page-content-box {
        flex-direction: column;
    }

    .ship-page-content-box_reversed .ship-page-content-box-image:last-child {
        transform: initial;
    }

    .ship-page-content-box-images,
    .ship-page-content-box-info {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .ship-page-content-box {
        padding: 1rem 0;
    }

    .ship-page-content-box-image {
        height: 350px;
    }
}

@media (max-width: 600px) {
    .ship-page-content-box {
        min-height: auto;
    }

    .ship-page-content-box-image {
        height: 200px;
    }
}

@media (max-width: 450px) {
    .ship-page-content-box {
        min-height: auto;
    }

    .ship-page-content-box-info__title {
        font-size: 1.8rem;
    }

    .ship-page-content-box-image {
        height: 200px;
        width: 100%;
    }

    .ship-page-content-box-image__src {
        object-fit: cover;
    }
    
    .ship-page-content-box-image:last-child {
        position: static;
        transform: initial;
    }
}